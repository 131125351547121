import React, { FC, useContext, useEffect, useState } from 'react'
import {
  SingleValueProps,
  OptionProps,
  DropdownIndicatorProps,
  components,
} from 'react-select'
import { observer } from 'mobx-react'
import {
  Container,
  Flag,
  ItemInner,
  LanguageSelect,
} from './LanguagePicker.styles'
import { getQueryVariable } from '../../methods/getQueryVariable'
import { rootStore } from '../../store/Root.store'
import { Language } from '../../types/translationsTypes'
import { StoreContext } from '../../components/App'
import { languageList } from '../CountrySelect/languageList'

const { Option, SingleValue, DropdownIndicator } = components

export interface SelectItem {
  value?: string
  label?: string
  icon?: string
}

export interface LanguagePickerProps {
  acceptedLanguages: string[]
}

const LanguageOption: FC<OptionProps<SelectItem>> = (props) => {
  const { data } = props
  const Icon = data?.icon

  return (
    <Option {...props}>
      <ItemInner>
        {Icon && <Flag>{<Icon />}</Flag>}
        <span>{data.label}</span>
      </ItemInner>
    </Option>
  )
}

const LanguageSingleValue: FC<SingleValueProps<SelectItem>> = (props) => {
  const { data } = props
  const Icon = data?.icon

  return (
    <SingleValue {...props}>
      <ItemInner>
        {Icon && <Flag>{<Icon />}</Flag>}
        <span>{data.label}</span>
      </ItemInner>
    </SingleValue>
  )
}

const LanguageDropdownIndicator: FC<DropdownIndicatorProps> = (props) => (
  <DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1094 9.19038C17.3633 9.44422 17.3633 9.85578 17.1094 10.1096L12.1094 15.1096C11.8556 15.3635 11.444 15.3635 11.1902 15.1096L6.19019 10.1096C5.93635 9.85578 5.93635 9.44422 6.19019 9.19038C6.44403 8.93654 6.85558 8.93654 7.10942 9.19038L11.6498 13.7308L16.1902 9.19038C16.444 8.93654 16.8556 8.93654 17.1094 9.19038Z"
        fill="#000076"
      />
    </svg>
  </DropdownIndicator>
)

export const LanguagePicker: FC = observer(() => {
  const store = useContext(StoreContext)
  const { acceptedLanguages } = store.AppState
  const { language } = store.TranslationsState

  const languageFromQuery = getQueryVariable('language')

  useEffect(() => {
    if (
      languageFromQuery &&
      acceptedLanguages &&
      acceptedLanguages.includes(languageFromQuery.toLowerCase())
    ) {
      rootStore.TranslationsState.setTranslations(languageFromQuery as Language)

      //remove query param from URL after setting language
      const currentUrl = window.location.href
      const url = new URL(currentUrl)
      url.searchParams.delete('language')
      window.history.pushState({}, '', url.toString())
    }
  }, [acceptedLanguages])

  return (
    acceptedLanguages && (
      <Container>
        <LanguageSelect
          name={'language-select'}
          value={languageList.find((lang) => lang.value === language)}
          onChange={(newValue: SelectItem) =>
            store.TranslationsState.setTranslations(newValue.value as Language)
          }
          options={languageList.filter((lang) =>
            acceptedLanguages.includes(lang.value)
          )}
          components={{
            Option: LanguageOption,
            SingleValue: LanguageSingleValue,
            DropdownIndicator: LanguageDropdownIndicator,
          }}
        />
      </Container>
    )
  )
})
